import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.Part background="white headline">
                    <Container.Row>
                        <div className="col-12">
                            <h1>Impressum</h1>
                        </div>
                    </Container.Row>
                </Container.Part>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-12">
                            <p>
                                <strong>Herausgeber</strong><br/>
                                Bayerischer Landtag | Landtagsamt
                            </p>
                            <p>
                                <strong>Vorsitz des wissenschaftlichen Beirats</strong><br/>
                                Dr. Ludwig Spaenle, Prof. Dr. Ferdinand Kramer
                            </p>
                            <p>
                                <strong>Inhaltliche Konzeption und Texte</strong><br/>
                                Laura Mokrohs, M.A.
                            </p>
                            <p>
                                <strong>Graphic Novels</strong><br/>
                                Ingrid Sabisch
                            </p>
                            <p>
                                <strong>Lektorat</strong><br/>
                                Redaktionsbüro Eva Bachmann, München<br/>
                                Lektorat Kirsten Rachowiak, München
                            </p>
                            <p>
                                <strong>Gestalterische Konzeption, Ausstellungsarchitektur, Ausstellungsgrafik, Medienterminal</strong><br/>
                                Büro für Gestaltung Wangler und Abele, München<br/>
                                Frank Abele, Katja Bläßer, Martin Cymorek, Kathleen Dhimogjini,
                                Andreas Egensperger, Chiara Jäggi, Elias Schanderl
                            </p>
                            <p>
                                <strong>Realisierung der Website und Programmierung des Medienterminals</strong><br/>
                                <a href="https://www.simplethings.de/" title="externer Inhalt"
                                   target="_blank" rel="noreferrer">SimpleThings GmbH</a>, Bonn
                            </p>
                            <p>
                                <strong>Ausstellungsbau</strong><br/>
                                Kreative Verkaufsförderung Messebauagentur GmbH Eichinger, Aying
                            </p>
                            <p>
                                <strong>Bildnachweis</strong><br/>
                                Der Bildnachweis wurde bei den einzelnen Abbildungen vermerkt.<br/>Sofern keine Angabe
                                gemacht wurde, liegt das Urheberrecht des Bildes beim Bayerischen Landtag.
                            </p>
                            <p>
                                München, Oktober 2021
                            </p>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
